import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/pmaxh/Documents/node-projects/website/src/components/page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "https://blar.tk"
      }}>{`Blar.tk`}</a>{` is a very simple URL shortener. It is entirely cloud-based, using Firebase Hosting for the website hosting, Firebase Cloud Firestore to store the URLs and configuration, and Firebase Functions for shortening and retrieving URLs. The source for the site is hosted `}<a parentName="p" {...{
        "href": "https://github.com/pmaxhogan/blar/"
      }}>{`on Github`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      